import React from 'react';
import {
  Breadcrumb,
  Button,
  Col,
  Collapse,
  Divider,
  Layout,
  message,
  Popconfirm,
  Row,
  Switch,
  Tooltip,
} from 'antd';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import Icon, {
  AppstoreOutlined,
  DeleteOutlined,
  EditOutlined,
  FilePdfOutlined,
  PlusOutlined,
  RightSquareOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import { navigate } from '@reach/router';
import { CSVLink } from 'react-csv';
import DataTable from '../../../components/shared/DataTable';
import {
  AuthActions,
  CourseActions,
  FilterActions,
  UtilsActions,
} from '../../../app/redux/actions';
import {
  AuthSelectors,
  CourseSelectors,
  FilterSelector,
  LoadingSelectors,
} from '../../../app/redux/reducers';
import { ReactComponent as ModuleIcon } from '../../../app/assets/img/course_icon_blue.svg';
import * as DateUtils from '../../../app/utils/date';
import AdvancedButton from '../../../components/shared/AdvancedButton';
import AdvancedInput from '../../../components/shared/AdvancedInput';
import AdvancedSelect from '../../../components/shared/AdvancedSelect/AdvancedSelect';
import { CourseType } from '../../../app/enum/courseType';
import { ProfileType } from '../../../app/enum/profileType';
import { cleanPagination } from '../../../app/utils/pagination';
import CourseApi from '../../../app/api/course';
import { generateAndDownloadPdf } from '../../../app/utils/pdf/tablePdf';
import { prepareDataToCsv } from '../../../app/utils/csv';

const { Panel } = Collapse;

class Modules extends React.Component {
  constructor (props) {
    super(props);
    const { coursesFilters } = props;
    const { modulesFilter } = coursesFilters;

    this.state = {
      exportingDataLoading: false,
      exportingPfdDataLoading: false,
      csvData: '',
      optionsPdf: {
        orientation: 'landscape',
      },
      params: {
        ...cleanPagination,
        ...modulesFilter,
        title: modulesFilter?.title ?? '',
        parentId: modulesFilter?.parentId ?? '',
        supportsDaily: modulesFilter?.supportsDaily ?? '',
        orderBy: 'createdAt',
        isDESC: true,
        type: CourseType.MODULE
      },
      company: '',
      companyList: [],
    };
    this.pdfLink = React.createRef();
    this.csvLink = React.createRef();
    this.dataTableRef = React.createRef();
  }

  async componentDidMount () {
    const { searchCourses, coursesFilters } = this.props;
    const { modulesFilter } = coursesFilters;
    this.getCompanyList()
    this.setState({
      params: { ...this.state.params, ...modulesFilter },
    });
    await searchCourses({
      type: CourseType.COURSE,
    });
  }

  csvExport = async (exportAllDataFromApi) => {
    this.setState({exportingDataLoading: true})

    const preparedDataToCsv = await prepareDataToCsv(
      this.props.modulesPaginated?.rows,
      (row) => ({
        title: row.title,
        relatedCourse: row.course && row.course.title,
        createdAt: DateUtils.humanizeDateTime(
          row.createdAt,
          'DD/MM/YYYY HH:mm',
        ),
        isEnabled: row.isEnabled,
      }),
      exportAllDataFromApi,
      this.props.modulesPaginated?.count,
      CourseApi.getCoursesPaginated,
      this.state.params,
    )

    this.setState({exportingDataLoading: false})

    if (preparedDataToCsv) {
      this.setState({ csvData: preparedDataToCsv }, () => {
        setTimeout(() => {
          this.csvLink.current.link.click();
        });
      })
    }else{
      message.error(I18n.t('shared.awaitData'))
    }
  };

  exportPdfData = async (exportAllDataFromApi) => {
    this.setState({exportingPfdDataLoading: true})

    const tableHeadersToPrint = [
      I18n.t('routes.panel.modules.dataTable.columns.title.title'),
      I18n.t('routes.panel.modules.dataTable.columns.course.title'),
      I18n.t('routes.panel.modules.dataTable.columns.createdAt.title'),
      I18n.t('routes.panel.contents.dataTable.columns.isEnabled.title'),
    ]

    const generated = await generateAndDownloadPdf(
      this.props.modulesPaginated.rows,
      tableHeadersToPrint,
      I18n.t('routes.panel.modules.sidebarTitle'),
      12,
      12,
      (data) => ([
        data.title || '--',
        data.course?.title || '--',
        DateUtils.humanizeDate(data.createdAt, 'DD/MM/YYYY') || '--',
        data.isEnabled ? I18n.t('shared.affirmative.yes') : I18n.t('shared.affirmative.no') || '--'
      ]),
      exportAllDataFromApi,
      this.props.modulesPaginated?.count,
      CourseApi.getCoursesPaginated,
      this.state.params,
      I18n.t('routes.panel.modules.sidebarTitle')
    )

    this.setState({exportingPfdDataLoading: false})

    if (!generated) {
      message.error(I18n.t('shared.awaitData'))
    }
  }

  updateModule = (id, data) => {
    const { updateModule } = this.props;

    try {
      updateModule(id, data, () => {
        message.success(I18n.t('routes.panel.moduleDetails.messages.success'));
        this.getModules(this.state.params)
      });
    } catch (e) {
      message.error(
        I18n.t('routes.panel.moduleDetails.messages.errors.generic'),
      );
    }
  };

  getModules = (params, isFromDataTable, isReset) => {
    const { me, getModulesPaginated, coursesFilters } = this.props;
    const { modulesFilter } = coursesFilters;

    if (!isFromDataTable) {
      this.setState({
        params: {
          ...this.state.params,
          ...params,
        },
      });
    }

    if (!isReset) {
      params = {
        ...modulesFilter,
        ...params,
      };
    }

    this.setState({ params  });
    getModulesPaginated(params, me, CourseType.MODULE);
    this.props.setCoursesModulesFilters(params);
  };

  fieldChange = (name, value) => {
    const { params } = this.state;
    params[name] = value ? value.toString() : null;
    this.setState({ params });
  };

  getCompanyList = async () => {
    const { getCompanies } = this.props;
    getCompanies((data) => {
      this.setState({ companyList: data });
    });
  }

  applyAdvancedFilters = (event, params) => {
    event.preventDefault();
    const { company } = this.state

    if (company) {
      params.companyId = company;
    }

    this.props.setCoursesModulesFilters({ ...params, ...cleanPagination });
    this.getModules({ ...params, ...cleanPagination}, true, true);
  };

  cleanAdvancedFilters = () => {
    const cleanParams = {
      title: '',
      parentId: '',
      supportsDaily: '',
      companyId: '',
      ...cleanPagination
    };
    this.props.setCoursesModulesFilters(null);
    this.setState({ company: '' });
    this.getModules(cleanParams);
  };

  removeModule (id) {
    this.props.removeModule(id, () => {
      message.success(I18n.t('routes.panel.modules.deleteSucces'));
      this.dataTableRef.current.reset();
    });
  }

  render () {
    const { Content } = Layout;
    const { params, company, companyList } = this.state;
    const { modulesPaginated, loading, searchedCourses, me } = this.props;

    return (
      <>
        {!this.props.isCreating && (
          <>
            <Content className='panel__layout__content panel__layout__content--breadcrumb'>
              <Breadcrumb>
                <Breadcrumb.Item>
                  <span>{I18n.t('routes.panel.pageTitle')}</span>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                  <span>{I18n.t('routes.panel.modules.pageTitle')}</span>
                </Breadcrumb.Item>
              </Breadcrumb>
            </Content>

            <Content className='panel__layout__content panel__layout__content--advanced-filter'>
              <Collapse className='advanced-filter'>
                <Panel header={I18n.t('shared.advancedFilters.title')}
                  key='1'>
                  <form
                    onSubmit={(event) =>
                      this.applyAdvancedFilters(event, this.state.params)
                    }
                  >
                    <Row gutter={16}>
                      <Col span={8}>
                        <AdvancedInput
                          value={params && params.title}
                          onChange={(val) => this.fieldChange('title', val)}
                          placeholder={I18n.t('shared.type')}
                          label={I18n.t('shared.advancedFilters.form.name')}
                        />
                      </Col>
                      <Col span={8}>
                        <AdvancedSelect
                          value={
                            (searchedCourses && params && params.parentId) ||
                            undefined
                          }
                          onChange={(val) => {
                            this.fieldChange('parentId', val);
                          }}
                          options={searchedCourses}
                          label={I18n.t('shared.advancedFilters.form.parentId')}
                        />
                      </Col>
                      <Col span={8}>
                        <AdvancedSelect
                          label={I18n.t('routes.panel.questionDatabase.dataTable.filters.company')}
                          value={company}
                          onChange={(val) => this.setState({company: val})}
                          options={companyList}
                        />
                      </Col>
                    </Row>

                    <Row gutter={16}>
                      <Col
                        span={24}
                        className='advanced-filter__search-button text-right'
                      >
                        <AdvancedButton
                          type='link'
                          text={I18n.t(
                            'shared.advancedFilters.clearButtonText',
                          )}
                          onClick={() => this.cleanAdvancedFilters()}
                        />
                        <AdvancedButton
                          htmlType='submit'
                          text={I18n.t(
                            'shared.advancedFilters.filterButtonText',
                          )}
                          icon={<SearchOutlined />}
                        />
                      </Col>
                    </Row>
                  </form>
                </Panel>
              </Collapse>
            </Content>
          </>
        )}

        <Content className='panel__layout__content'>
          <div className='modules'>
            <Row className='d-flex justify-content-between'>
              <Col>
                <h3>
                  <span className='panel__layout__content__title__value__icon'>
                    <Icon component={ModuleIcon} />
                  </span>
                  {I18n.t('routes.panel.modules.pageTitle')}
                </h3>
              </Col>
              <Col className='text-right'>
                {([
                  ProfileType.ADMIN,
                ].includes(me?.profileType)) && (
                  <>
                    <AdvancedButton
                      icon={<FilePdfOutlined />}
                      text={I18n.t('shared.exportPdfButton')}
                      onClick={() => this.exportPdfData()}
                      disabled={loading > 0}
                    />
                    <Divider type='vertical' />

                    <AdvancedButton
                      icon={<FilePdfOutlined />}
                      text={I18n.t('shared.exportAllPdfButton')}
                      onClick={() => this.exportPdfData(true)}
                      loading={this.state.exportingPfdDataLoading}
                      disabled={loading > 0 || this.state.exportingPfdDataLoading}
                    />
                    <Divider type='vertical' />
                    <AdvancedButton
                      icon={<AppstoreOutlined />}
                      text={I18n.t('shared.exportCsvButtonText')}
                      onClick={() => this.csvExport()}
                      disabled={loading > 0}
                    />

                    <Divider type='vertical' />
                    <AdvancedButton
                      icon={<AppstoreOutlined />}
                      text={I18n.t('shared.exportAllCsvButtonText')}
                      onClick={() => this.csvExport(true)}
                      loading={this.state.exportingDataLoading}
                      disabled={loading > 0 || this.state.exportingDataLoading}
                    />

                    <CSVLink
                      data={this.state.csvData}
                      filename={I18n.t('routes.panel.moduleDetails.csvFileName')}
                      ref={this.csvLink}
                      style={{ display: 'none' }}
                    >
                      {I18n.t('shared.exportCsvButtonText')}
                    </CSVLink>
                  </>
                )}

                <Divider type='vertical' />
                {!this.props.isCreating && (
                  <AdvancedButton
                    text={I18n.t('routes.panel.modules.addNewButtonText')}
                    href={`${I18n.t('routes.panel.moduleDetails.url')}add`}
                  />
                )}
              </Col>
            </Row>

            <Divider />
            <div ref={this.pdfLink}>
              <DataTable
                getMethod={(parameters) => this.getModules(parameters, true)}
                data={modulesPaginated}
                loading={loading > 0}
                ref={this.dataTableRef}
                showSizeChanger
                pageSizeOptions={
                  modulesPaginated &&
                    modulesPaginated.count &&
                    modulesPaginated.count > 100
                    ? [ '10', '50', '100', modulesPaginated.count ]
                    : [ '10', '20', '50', '100' ]
                }
                params={params}
                columns={[
                  {
                    ellipsis: true,
                    key: I18n.t(
                      'routes.panel.modules.dataTable.columns.title.key',
                    ),
                    title: I18n.t(
                      'routes.panel.modules.dataTable.columns.title.title',
                    ),
                    render: (value) => value || '--',
                  },
                  {
                    ellipsis: true,
                    key: I18n.t(
                      'routes.panel.modules.dataTable.columns.course.key',
                    ),
                    title: I18n.t(
                      'routes.panel.modules.dataTable.columns.course.title',
                    ),
                    render: (value) => (value && value.title) || '--',
                  },
                  {
                    ellipsis: true,
                    key: I18n.t(
                      'routes.panel.modules.dataTable.columns.course.key',
                    ),
                    title: I18n.t(
                      'routes.panel.questionDatabase.dataTable.columns.company.title',
                    ),
                    render: (value) => (value && value.company && value.company.name) || '--',
                  },
                  {
                    width: '120px',
                    key: I18n.t(
                      'routes.panel.modules.dataTable.columns.createdAt.key',
                    ),
                    title: I18n.t(
                      'routes.panel.modules.dataTable.columns.createdAt.title',
                    ),
                    render: (value) => (
                      <div className='display_flex justify_content'>
                        {DateUtils.humanizeDate(value, 'DD/MM/YYYY') || '--'}
                      </div>
                    ),
                  },
                  {
                    width: '90px',
                    key: I18n.t(
                      'routes.panel.contents.dataTable.columns.isEnabled.key',
                    ),
                    title: I18n.t(
                      'routes.panel.contents.dataTable.columns.isEnabled.title',
                    ),
                    render: (value, row) => (
                      <div className='display_flex justify_content'>
                        <Switch
                          checked={value}
                          onChange={() =>
                            this.updateModule(row.id, { isEnabled: !value })
                          }
                        />
                      </div>
                    ),
                  },
                  {
                    width: '120px',
                    key: I18n.t(
                      'routes.panel.modules.dataTable.columns.actions.key',
                    ),
                    title: '',
                    render: (id) => (
                      <div className='dataTable__item--right'>
                        <Tooltip
                          title={I18n.t(
                            'routes.panel.quiz.dataTable.columns.actions.goDuplicate',
                          )}
                          overlayStyle={{ fontSize: 11 }}
                        >
                          <Button
                            type='link'
                            icon={<PlusOutlined />}
                            onClick={() =>
                              navigate(
                                `${I18n.t(
                                  'routes.panel.moduleDetails.urlDuplicate',
                                )}${id}`,
                              )
                            }
                          />
                        </Tooltip>
                        <Tooltip
                          title={I18n.t(
                            'routes.panel.modules.dataTable.columns.actions.goToDetailsText',
                          )}
                          overlayStyle={{ fontSize: 11 }}
                        >
                          <Button
                            type='link'
                            icon={<RightSquareOutlined />}
                            onClick={() =>
                              navigate(
                                `${I18n.t('routes.panel.moduleContents.url')}` +
                                `${id}${I18n.t(
                                  'routes.panel.moduleContents.sufixUrl',
                                )}`,
                              )
                            }
                          />
                        </Tooltip>
                        <Tooltip
                          title={I18n.t(
                            'routes.panel.modules.dataTable.columns.actions.goToEditText',
                          )}
                          overlayStyle={{ fontSize: 11 }}
                        >
                          <Button
                            type='link'
                            icon={<EditOutlined />}
                            onClick={() =>
                              navigate(
                                `${I18n.t(
                                  'routes.panel.moduleDetails.url',
                                )}${id}`,
                              )
                            }
                          />
                        </Tooltip>
                        {!this.props.isCreating && ([ ProfileType.ADMIN, ProfileType.TEACHER ]).includes(this.props.me?.profileType) && (
                          <Popconfirm
                            placement='left'
                            title={I18n.t('shared.confirmTitle')}
                            onConfirm={() => this.removeModule(id)}
                            okText={I18n.t('shared.yes')}
                            cancelText={I18n.t('shared.no')}
                          >
                            <Tooltip
                              title={I18n.t(
                                'routes.panel.modules.dataTable.columns.actions.removeText',
                              )}
                              overlayStyle={{ fontSize: 11 }}
                            >
                              <Button type='link'
                                icon={<DeleteOutlined />} />
                            </Tooltip>
                          </Popconfirm>
                        )}
                      </div>
                    ),
                  },
                ]}
              />
            </div>
          </div>
        </Content>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  modulesPaginated: CourseSelectors.getCoursesPaginated(state),
  loading: LoadingSelectors.getLoading(state),
  me: AuthSelectors.getMe(state),
  searchedCourses: CourseSelectors.searchCourses(state),
  coursesFilters: FilterSelector.getCoursesFilters(state),
});

const mapDispatchToProps = (dispatch) => ({
  getModulesPaginated: (parameters, me, type) =>
    dispatch(CourseActions.getCoursesPaginated(parameters, me, type)),
  updateModule: (id, data, callback) =>
    dispatch(CourseActions.updateCourse(id, data, callback)),
  removeModule: (id, callback) =>
    dispatch(CourseActions.removeCourse(id, callback)),
  getMe: () => dispatch(AuthActions.getMe()),
  searchCourses: (params) => dispatch(CourseActions.searchCourses(params)),
  setCoursesModulesFilters: (params) =>
    dispatch(FilterActions.setCoursesModulesFilters(params)),
  getCompanies: (callback) => dispatch(UtilsActions.getCompanies(callback)),
});

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(Modules);
