import getInstance from './config';

export default class TrailsApi {
  static async getTrailsPaginated (params) {
    const instance = await getInstance();
    const { data } = await instance.get('/trail', { params });
    return data;
  }

  static async postTrail (trailData) {
    const instance = await getInstance();
    const { data } = await instance.post('/trail', trailData);
    return data;
  }

  static async update (id, companyData) {
    const instance = await getInstance();
    const { data } = await instance.put(`/trail/${id}`, companyData);
    return data;
  }

  static async getTrailDetails (id) {
    const instance = await getInstance();
    const { data } = await instance.get(`/trail/track-details/${id}`);
    return data;
  }

  static async deleteTrailById (id) {
    const instance = await getInstance();
    const { data } = await instance.delete(`/trail/${id}`);
    return data;
  }

  static async getUsersProgress (id) {
    const instance = await getInstance();
    const { data } = await instance.get(`/trail/users-progress/${id}`);
    return data;
  }

  static async getTrailStatisticsByUserId (trailId, userId) {
    const instance = await getInstance();
    const { data } = await instance.get(`/trail/users-trail-detail/${trailId}/${userId}`);
    return data;
  }
}
