import { Router } from '@reach/router';
import React from 'react';
import { I18n } from 'react-redux-i18n';
import Panel from './Panel';
import Public from './Public';
import Login from '../../containers/login';
import PasswordRecovery from '../../containers/passwordRecovery';
import Users from '../../containers/panel/users';
import UserDetails from '../../containers/panel/userDetails';
import Contents from '../../containers/panel/contents/Contents';
import ContentDetails from '../../containers/panel/contentDetails/ContentDetails';
import UserRequests from '../../containers/panel/userRequests/UserRequests';
import PushNotificationDetails from '../../containers/panel/pushNotificationDetails';
import PushNotifications from '../../containers/panel/pushNotifications';
import UserInfo from '../../containers/panel/userInfo';
import Courses from '../../containers/panel/courses';
import CourseDetails from '../../containers/panel/courseDetails';
import Modules from '../../containers/panel/modules';
import ModuleDetails from '../../containers/panel/moduleDetails';
import Statistics from '../../containers/panel/statistics';
import UserStatistics from '../../containers/panel/userStatistics';
import StatisticList from '../../containers/panel/statisticList';
import ContentCalendar from '../../containers/panel/contentCalendar/ContentCalendar';
import Hospitals from '../../containers/panel/hospitals';
import HospitalDetails from '../../containers/panel/hospitalDetails';
import Companies from '../../containers/panel/companies';
import CompanyDetails from '../../containers/panel/companyDetails';
import Categories from '../../containers/panel/categories';
import CategoryDetails from '../../containers/panel/categoryDetails';
import PasswordChanged from '../../containers/passwordChanged/PasswordChanged';
import ContentStatistics from '../../containers/panel/contentStatistics';
import QuizQuestion from '../../containers/panel/quiz-question/QuizQuestion';
import QuizExame from '../../containers/panel/quiz-exame/QuizExame';
import NewQuestion from '../../containers/panel/new-question/NewQuestion';
import NewExame from '../../containers/panel/new-exame/NewExame';
import StatisticsQuiz from '../../containers/panel/statistics-quiz/StatisticsQuiz';
import MediaAnswerDetail from '../../containers/panel/media-answer-detail/MediaAnswerDetail';
import ChatList from '../../containers/panel/chat/ChatList';
import ChatPage from '../../containers/panel/chat/ChatPage';
import AssignStudents from '../../containers/panel/AssignStudents/AssignStudents';
import Administrators from '../../containers/panel/administrators/Administrators';
import AdministratorDetails from '../../containers/panel/administratorDetails/AdministratorDetails';
import ContentChat from '../../containers/panel/chat/ContentChat';
import ReleaseNotes from '../../containers/panel/releaseNotes/ReleaseNotes';
import CreateReleaseNotes from '../../containers/panel/createReleaseNotes/CreateReleaseNotes';
import NoteDetails from '../../containers/panel/noteDetails/NoteDetails';
import QuestionDatabase from '../../containers/panel/questionDatabase/QuestionDatabase';
import AssignQuestionDatabase from '../../containers/panel/assignQuestionDatabase/AssignQuestionDatabase';
import ChatQuestionDatabase from '../../containers/panel/chatQuestionDatabase/ChatQuestionDatabase';
import QuestionDatabaseCorrections from '../../containers/panel/questionDatabaseCorrections/QuestionDatabaseCorrections';
import UserQuizzes from '../../containers/panel/user-quizzes/UserQuizzes';
import OrderContents from '../../containers/panel/order-contents/OrderContents';
import Trails from '../../containers/panel/trails/Trails';
import { CreateTrail } from '../../containers/panel/trails/createTrail/CreateTrail';
import { UpdateTrail } from '../../containers/panel/trails/updateTrail/UpdateTrail';
import { StudentsStatistics } from '../../containers/panel/trails/Students-statistics/Students-statistics';

class Content extends React.PureComponent {
  // eslint-disable-next-line class-methods-use-this
  render () {
    return (
      <Router>
        <Public
          path={I18n.t('routes.login.url')}
          container={<Login />}
          title={`${I18n.t('routes.login.pageTitle')} - ${I18n.t(
            'application.name',
          )}`}
        />
        <Public
          path={I18n.t('routes.passwordRecovery.urlApp')}
          container={<PasswordRecovery />}
          title={`${I18n.t('routes.passwordRecovery.pageTitle')} - ${I18n.t(
            'application.name',
          )}`}
        />
        <Public
          path={I18n.t('routes.passwordRecovery.url')}
          container={<PasswordRecovery />}
          title={`${I18n.t('routes.passwordRecovery.pageTitle')} - ${I18n.t(
            'application.name',
          )}`}
        />
        <Public
          path={I18n.t('routes.passwordChanged.url')}
          container={<PasswordChanged />}
          title={`${I18n.t('routes.passwordRecovery.pageTitle')} - ${I18n.t(
            'application.name',
          )}`}
        />
        <Panel
          path={I18n.t('routes.panel.users.url')}
          container={<Users isFilteredByCourse />}
          title={`${I18n.t('routes.panel.users.pageTitle')} - ${I18n.t(
            'application.name',
          )}`}
        />
        <Panel
          path={`${I18n.t('routes.panel.userDetails.url')}:id`}
          container={<UserDetails />}
          title={`${I18n.t('routes.panel.userDetails.pageTitle')} - ${I18n.t(
            'application.name',
          )}`}
        />
        <Panel
          path={`${I18n.t('routes.panel.userDetails.url')}add`}
          container={<UserDetails />}
          title={`${I18n.t('routes.panel.userDetails.pageTitle')} - ${I18n.t(
            'application.name',
          )}`}
        />
        <Panel
          path={`${I18n.t('routes.panel.userDetails.url')}:id${I18n.t(
            'routes.panel.userDetails.sufixUrl',
          )}`}
          container={<Users isFilteredByCourse />}
          title={`${I18n.t('routes.panel.userDetails.pageTitle')} - ${I18n.t(
            'application.name',
          )}`}
        />
        <Panel
          path={`${I18n.t('routes.panel.userDetails.url')}:id${I18n.t(
            'routes.panel.userDetails.sufixCompaniesUrl',
          )}`}
          container={<Users isFilteredByCompany />}
          title={`${I18n.t('routes.panel.userDetails.pageTitle')} - ${I18n.t(
            'application.name',
          )}`}
        />
        <Panel
          path={`${I18n.t('routes.panel.questionDatabase.url')}`}
          container={<QuestionDatabase />}
          title={`${I18n.t(
            'routes.panel.questionDatabase.pageTitle',
          )} - ${I18n.t('application.name')}`}
        />
        <Panel
          path={`${I18n.t(
            'routes.panel.questionDatabase.questionDatabaseAssign.url',
          )}`}
          container={<AssignQuestionDatabase />}
          title={`${I18n.t(
            'routes.panel.questionDatabase.pageTitle',
          )} - ${I18n.t('application.name')}`}
        />
        <Panel
          path={`${I18n.t('routes.panel.questionDatabase.chat.url')}`}
          container={<ChatQuestionDatabase />}
          title={`${I18n.t(
            'routes.panel.questionDatabase.pageTitle',
          )} - ${I18n.t('application.name')}`}
        />
        <Panel
          path={`${I18n.t('routes.panel.questionDatabase.corrections.url')}`}
          container={<QuestionDatabaseCorrections />}
          title={`${I18n.t(
            'routes.panel.questionDatabase.corrections.pageTitle',
          )} - ${I18n.t('application.name')}`}
        />
        <Panel
          path={`${I18n.t('routes.panel.releaseNotes.url')}`}
          container={<ReleaseNotes />}
          title={`${I18n.t('routes.panel.releaseNotes.pageTitle')} - ${I18n.t(
            'application.name',
          )}`}
        />
        <Panel
          path={`${I18n.t('routes.panel.releaseNotes.url')}add`}
          container={<CreateReleaseNotes />}
          title={`${I18n.t('routes.panel.releaseNotes.pageTitle')} - ${I18n.t(
            'application.name',
          )}`}
        />
        <Panel
          path={`${I18n.t('routes.panel.releaseNotes.url')}:id`}
          container={<CreateReleaseNotes />}
          title={`${I18n.t('routes.panel.releaseNotes.pageTitle')} - ${I18n.t(
            'application.name',
          )}`}
        />
        <Panel
          path={`${I18n.t('routes.panel.releaseNotes.noteDetails.url')}:id`}
          container={<NoteDetails />}
          title={`${I18n.t('routes.panel.userDetails.pageTitle')} - ${I18n.t(
            'application.name',
          )}`}
        />
        <Panel
          path={`${I18n.t('routes.panel.userInfo.url')}:id`}
          container={<UserInfo />}
          title={`${I18n.t('routes.panel.userDetails.pageTitle')} - ${I18n.t(
            'application.name',
          )}`}
        />
        <Panel
          path={I18n.t('routes.panel.administrators.url')}
          container={<Administrators />}
          title={`${I18n.t('routes.panel.administrators.pageTitle')} - ${I18n.t(
            'application.name',
          )}`}
        />
        <Panel
          path={`${I18n.t('routes.panel.administratorDetails.url')}:id`}
          container={<AdministratorDetails />}
          title={`${I18n.t(
            'routes.panel.administratorDetails.pageTitle',
          )} - ${I18n.t('application.name')}`}
        />
        <Panel
          path={`${I18n.t('routes.panel.administratorDetails.url')}add`}
          container={<AdministratorDetails />}
          title={`${I18n.t(
            'routes.panel.administratorDetails.pageTitle',
          )} - ${I18n.t('application.name')}`}
        />
        <Panel
          path={I18n.t('routes.panel.contents.url')}
          container={<Contents />}
          title={`${I18n.t('routes.panel.contents.pageTitle')} - ${I18n.t(
            'application.name',
          )}`}
        />
        <Panel
          path={`${I18n.t('routes.panel.contentDetails.url')}:id`}
          container={<ContentDetails />}
          title={`${I18n.t('routes.panel.contentDetails.pageTitle')} - ${I18n.t(
            'application.name',
          )}`}
        />
        <Panel
          path={`${I18n.t('routes.panel.contentDetails.url')}add`}
          container={<ContentDetails />}
          title={`${I18n.t('routes.panel.contentDetails.pageTitle')} - ${I18n.t(
            'application.name',
          )}`}
        />
        <Panel
          path={`${I18n.t('routes.panel.contentDetails.urlDuplicate')}:id`}
          container={<ContentDetails isDuplicating />}
          title={`${I18n.t('routes.panel.contentDetails.pageTitle')} - ${I18n.t(
            'application.name',
          )}`}
        />
        <Panel
          path={I18n.t('routes.panel.hospitals.url')}
          container={<Hospitals />}
          title={`${I18n.t('routes.panel.hospitals.pageTitle')} - ${I18n.t(
            'application.name',
          )}`}
        />
        <Panel
          path={`${I18n.t('routes.panel.hospitalDetails.url')}:id`}
          container={<HospitalDetails />}
          title={`${I18n.t(
            'routes.panel.hospitalDetails.pageTitle',
          )} - ${I18n.t('application.name')}`}
        />
        <Panel
          path={`${I18n.t('routes.panel.hospitalDetails.url')}add`}
          container={<HospitalDetails />}
          title={`${I18n.t(
            'routes.panel.hospitalDetails.pageTitle',
          )} - ${I18n.t('application.name')}`}
        />
        <Panel
          path={I18n.t('routes.panel.companies.url')}
          container={<Companies />}
          title={`${I18n.t('routes.panel.companies.pageTitle')} - ${I18n.t(
            'application.name',
          )}`}
        />
        <Panel
          path={`${I18n.t('routes.panel.companyDetails.url')}:id`}
          container={<CompanyDetails />}
          title={`${I18n.t('routes.panel.companyDetails.pageTitle')} - ${I18n.t(
            'application.name',
          )}`}
        />
        <Panel
          path={`${I18n.t('routes.panel.companyDetails.url')}add`}
          container={<CompanyDetails />}
          title={`${I18n.t('routes.panel.companyDetails.pageTitle')} - ${I18n.t(
            'application.name',
          )}`}
        />
        <Panel
          path={I18n.t('routes.panel.categories.url')}
          container={<Categories />}
          title={`${I18n.t('routes.panel.categories.pageTitle')} - ${I18n.t(
            'application.name',
          )}`}
        />
        <Panel
          path={`${I18n.t('routes.panel.categoryDetails.url')}:id`}
          container={<CategoryDetails />}
          title={`${I18n.t(
            'routes.panel.categoryDetails.pageTitle',
          )} - ${I18n.t('application.name')}`}
        />
        <Panel
          path={`${I18n.t('routes.panel.categoryDetails.url')}add`}
          container={<CategoryDetails />}
          title={`${I18n.t(
            'routes.panel.categoryDetails.pageTitle',
          )} - ${I18n.t('application.name')}`}
        />
        <Panel
          path={I18n.t('routes.panel.courses.url')}
          container={<Courses />}
          title={`${I18n.t('routes.panel.courses.pageTitle')} - ${I18n.t(
            'application.name',
          )}`}
        />
        <Panel
          path={`${I18n.t('routes.panel.courseDetails.url')}:id`}
          container={<CourseDetails />}
          title={`${I18n.t('routes.panel.courseDetails.pageTitle')} - ${I18n.t(
            'application.name',
          )}`}
        />
        <Panel
          path={`${I18n.t('routes.panel.orderCourses.url')}:id`}
          container={<OrderContents />}
          title={`${I18n.t('routes.panel.orderCourses.pageTitle')} - ${I18n.t(
            'application.name',
          )}`}
        />
        <Panel
          path={`${I18n.t('routes.panel.courseDetails.urlDuplicate')}:id`}
          container={<CourseDetails isDuplicating />}
          title={`${I18n.t('routes.panel.courseDetails.pageTitle')} - ${I18n.t(
            'application.name',
          )}`}
        />
        <Panel
          path={`${I18n.t('routes.panel.courseDetails.url')}add`}
          container={<CourseDetails />}
          title={`${I18n.t('routes.panel.courseDetails.pageTitle')} - ${I18n.t(
            'application.name',
          )}`}
        />
        <Panel
          path={I18n.t('routes.panel.knowledgeTrails.url')}
          container={<Trails />}
          title={`${I18n.t(
            'routes.panel.knowledgeTrails.pageTitle',
          )} - ${I18n.t('application.name')}`}
        />
        <Panel
          path={`${I18n.t('routes.panel.knowledgeTrails.url')}add`}
          container={<CreateTrail />}
          title={`${I18n.t(
            'routes.panel.knowledgeTrails.create.pageTitle',
          )} - ${I18n.t('application.name')}`}
        />
        <Panel
          path={`${I18n.t('routes.panel.knowledgeTrails.trailDetailsUrl')}/:id`}
          container={<UpdateTrail />}
          title={`${I18n.t(
            'routes.panel.knowledgeTrails.update.pageTitle',
          )} - ${I18n.t('application.name')}`}
        />
        <Panel
          path={`${I18n.t('routes.panel.knowledgeTrails.create.assignment.url')}/:trailId/:id`}
          container={<StudentsStatistics />}
          title={`${I18n.t(
            'routes.panel.knowledgeTrails.update.assignment.statistics.title',
          )} - ${I18n.t('application.name')}`}
        />
        <Panel
          path={I18n.t('routes.panel.modules.url')}
          container={<Modules />}
          title={`${I18n.t('routes.panel.modules.pageTitle')} - ${I18n.t(
            'application.name',
          )}`}
        />
        <Panel
          path={`${I18n.t('routes.panel.moduleDetails.url')}:id`}
          container={<ModuleDetails />}
          title={`${I18n.t('routes.panel.moduleDetails.pageTitle')} - ${I18n.t(
            'application.name',
          )}`}
        />
        <Panel
          path={`${I18n.t('routes.panel.moduleDetails.url')}add`}
          container={<ModuleDetails />}
          title={`${I18n.t('routes.panel.moduleDetails.pageTitle')} - ${I18n.t(
            'application.name',
          )}`}
        />
        <Panel
          path={`${I18n.t('routes.panel.moduleDetails.urlDuplicate')}:id`}
          container={<ModuleDetails isDuplicating />}
          title={`${I18n.t('routes.panel.moduleDetails.pageTitle')} - ${I18n.t(
            'application.name',
          )}`}
        />
        <Panel
          path={`${I18n.t('routes.panel.moduleContents.url')}:id${I18n.t(
            'routes.panel.moduleContents.sufixUrl',
          )}`}
          container={<Contents />}
          title={`${I18n.t('routes.panel.moduleContents.pageTitle')} - ${I18n.t(
            'application.name',
          )}`}
        />
        <Panel
          path={I18n.t('routes.panel.userRequests.url')}
          container={<UserRequests />}
          title={`${I18n.t('routes.panel.userRequests.pageTitle')} - ${I18n.t(
            'application.name',
          )}`}
        />
        <Panel
          path={`${I18n.t('routes.panel.contentCalendar.url')}:id`}
          container={<ContentCalendar />}
          title={`${I18n.t(
            'routes.panel.contentCalendar.pageTitle',
          )} - ${I18n.t('application.name')}`}
        />
        <Panel
          path={I18n.t('routes.panel.pushNotifications.url')}
          container={<PushNotifications />}
          title={`${I18n.t(
            'routes.panel.pushNotifications.pageTitle',
          )} - ${I18n.t('application.name')}`}
        />
        <Panel
          path={`${I18n.t('routes.panel.pushNotificationDetails.url')}:id`}
          container={<PushNotificationDetails />}
          title={`${I18n.t(
            'routes.panel.pushNotificationDetails.pageTitle',
          )} - ${I18n.t('application.name')}`}
        />
        <Panel
          path={`${I18n.t('routes.panel.pushNotificationDetails.url')}add`}
          container={<PushNotificationDetails />}
          title={`${I18n.t(
            'routes.panel.pushNotificationDetails.pageTitle',
          )} - ${I18n.t('application.name')}`}
        />
        <Panel
          path={I18n.t('routes.panel.statistics.url')}
          container={<Statistics />}
          title={`${I18n.t('routes.panel.statistics.pageTitle')} - ${I18n.t(
            'application.name',
          )}`}
        />
        <Panel
          path={`${I18n.t('routes.panel.statisticsList.url')}:id`}
          container={<StatisticList />}
          title={`${I18n.t('routes.panel.userStatistics.pageTitle')} - ${I18n.t(
            'application.name',
          )}`}
        />
        <Panel
          path={`${I18n.t('routes.panel.userStatistics.url')}:id`}
          container={<UserStatistics />}
          title={`${I18n.t('routes.panel.userStatistics.pageTitle')} - ${I18n.t(
            'application.name',
          )}`}
        />
        <Panel
          path={`${I18n.t('routes.panel.contentStatistics.url')}:id`}
          container={<ContentStatistics />}
          title={`${I18n.t(
            'routes.panel.contentStatistics.pageTitle',
          )} - ${I18n.t('application.name')}`}
        />
        <Panel
          path={I18n.t('routes.panel.quiz.urlQuiz')}
          container={<QuizExame />}
          title={`${I18n.t('routes.panel.quiz.pageQuiz')} - ${I18n.t(
            'application.name',
          )}`}
        />
        <Panel
          path={I18n.t('routes.panel.quiz.urlQuestion')}
          container={<QuizQuestion />}
          title={`${I18n.t('routes.panel.quiz.pageQuestion')} - ${I18n.t(
            'application.name',
          )}`}
        />
        <Panel
          path={`${I18n.t('routes.panel.quiz.urlQuestion')}add`}
          container={<NewQuestion />}
          title={`${I18n.t('routes.panel.quiz.pageQuestion')} - ${I18n.t(
            'application.name',
          )}`}
        />
        <Panel
          path={`${I18n.t('routes.panel.quiz.urlQuestion')}:id`}
          container={<NewQuestion editingQuestion />}
          title={`${I18n.t('routes.panel.quiz.pageQuestion')} - ${I18n.t(
            'application.name',
          )}`}
        />
        <Panel
          path={`${I18n.t('routes.panel.quiz.urlQuiz')}add`}
          container={<NewExame />}
          title={`${I18n.t('routes.panel.quiz.pageQuiz')} - ${I18n.t(
            'application.name',
          )}`}
        />
        <Panel
          path={`${I18n.t('routes.panel.quiz.urlQuiz')}:id`}
          container={<NewExame isEditing />}
          title={`${I18n.t('routes.panel.quiz.pageQuiz')} - ${I18n.t(
            'application.name',
          )}`}
        />
        <Panel
          path={`${I18n.t('routes.panel.quiz.urlQuizAddUsers')}:id`}
          container={<AssignStudents />}
          title={`${I18n.t('routes.panel.quiz.AssignStudents')} - ${I18n.t(
            'application.name',
          )}`}
        />
        <Panel
          path={`${I18n.t('routes.panel.quiz.urlQuizDuplicate')}:id`}
          container={<NewExame isDuplicating />}
          title={`${I18n.t('routes.panel.quiz.pageQuiz')} - ${I18n.t(
            'application.name',
          )}`}
        />
        <Panel
          path={`${I18n.t('routes.panel.quiz.urlQuizStatistics')}:id`}
          container={<StatisticsQuiz />}
          title={`${I18n.t('routes.panel.quiz.pageStatistics')} - ${I18n.t(
            'application.name',
          )}`}
        />
        <Panel
          path={`${I18n.t('routes.panel.quiz.urlQuizCorrections')}:id`}
          container={<MediaAnswerDetail />}
          title={`${I18n.t('routes.panel.quiz.pageCorrections')} - ${I18n.t(
            'application.name',
          )}`}
        />
        <Panel
          path={`${I18n.t('routes.panel.chat.url')}`}
          container={<ChatList />}
          title={`${I18n.t('routes.panel.chat.pageTitle')} - ${I18n.t(
            'application.name',
          )}`}
        />
        <Panel
          path={`${I18n.t('routes.panel.chat.url')}:id`}
          container={<ChatPage />}
          title={`${I18n.t('routes.panel.chat.pageTitle')} - ${I18n.t(
            'application.name',
          )}`}
        />
        <Panel
          path={`${I18n.t('routes.panel.contentChat.url')}`}
          container={<ContentChat />}
          title={`${I18n.t('routes.panel.contentChat.pageTitle')} - ${I18n.t(
            'application.name',
          )}`}
        />
        <Panel
          path={I18n.t('routes.panel.userQuiz.url', { userId: ':id' })}
          container={<UserQuizzes />}
          title={`${I18n.t('routes.panel.userQuiz.pageTitle')} - ${I18n.t(
            'application.name',
          )}`}
        />
      </Router>
    );
  }
}

export default Content;
