import React from 'react';
import { Breadcrumb, Col, Layout, Progress, Row } from 'antd';
import { I18n } from 'react-redux-i18n';
import Loading from '../../../../../components/shared/Loading/Loading';
import { Link } from '@reach/router';
import { CheckCircleOutlined, ClockCircleOutlined } from '@ant-design/icons';

const { Content } = Layout;

const StatisticsInfo = ({ info, trailId, loading }) => {
  const referenceTypeEnum = {
    EXTERNAL: 'YouTube',
    VIDEO: 'Video',
    AUDIO: 'Podcast',
    RESUME: 'Resumo',
    QUESTION: 'Questão',
    QUIZ: 'Simulado',
  };

  const validateStudentResponse = (content) => {
    const userResponse = content.userQuestionDatabaseAnswers[0]?.questionAnswerId;
    const isCorrect = content.answers.find(answer => answer.id === userResponse)?.isCorrect || false;

    return isCorrect ?
      I18n.t('routes.panel.knowledgeTrails.update.assignment.statistics.content.feedback.correct')
      : I18n.t('routes.panel.knowledgeTrails.update.assignment.statistics.content.feedback.wrong')
  }

  const findStudentResponseQuiz = (content) => {
    const questionsTotal = content.quiz.questions.length;
    let correctsResponses = 0;

    content.quiz.questions.forEach(question => {
      const userResponse = question.userAnswers[0]?.questionAnswerId;
      const isCorrect = question.answers.find(answer => answer.id === userResponse)?.isCorrect || false;

      if (isCorrect) {
        correctsResponses = correctsResponses + 1;
      }
    })

    return `${I18n.t('routes.panel.knowledgeTrails.update.assignment.statistics.content.feedback.quiz')} ${correctsResponses} de ${questionsTotal} ${I18n.t('routes.panel.knowledgeTrails.update.assignment.statistics.content.feedback.questions')}`
  }

  if(loading) return <Loading />;

  return (
    <div>
      <Content className='panel__layout__content panel__layout__content--breadcrumb'>
        <Breadcrumb>
          <Breadcrumb.Item>
            <span>{I18n.t('routes.panel.pageTitle')}</span>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to={`${I18n.t('routes.panel.knowledgeTrails.url')}`}>
              <span>{I18n.t('routes.panel.knowledgeTrails.sidebarTitle')}</span>
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to={`${I18n.t('routes.panel.knowledgeTrails.trailDetailsUrl')}/${trailId}`}>
              <span>{info.title}</span>
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <span>{I18n.t('routes.panel.knowledgeTrails.update.assignment.statistics.breadcrumbTitle')}</span>
          </Breadcrumb.Item>
        </Breadcrumb>
      </Content>

      <Content className='panel__layout__content'>
        <Col style={{ marginBottom: 20 }}>
          <h3>{I18n.t('routes.panel.knowledgeTrails.update.assignment.statistics.title')}: {info.title}</h3>
        </Col>
      </Content>
      <Content className='panel__layout__content trail_step_container'>
        <h4>{I18n.t('routes.panel.knowledgeTrails.update.assignment.statistics.student')} {info.studentName}</h4>
        <Row>
          <div className='progress_container'>
            <h4>{I18n.t('routes.panel.knowledgeTrails.update.assignment.statistics.progressTitle')}</h4>
            <Progress
              percent={info.progress}
              strokeColor='#1677ff'
              size={[ 800, 50 ]}
            />
          </div>
        </Row>
        <Col>
          {info.contents.map(content => {
            const validateStudentResponseQuestion = content.referenceType === 'QUESTION' ? validateStudentResponse(content['question-stats']) : '';
            const validateStudentResponseQuiz = content.referenceType === 'QUIZ' ? findStudentResponseQuiz(content['quiz-stats']) : '';
            return (
              <div
                key={content.name}
                className='content_container'
              >
                <div className='title_container'>
                  <span className='content_title'>{I18n.t('routes.panel.knowledgeTrails.update.assignment.statistics.content.title')} {referenceTypeEnum[content.referenceType]}</span>
                  <span>{content.title}</span>
                  {content.referenceType === 'QUESTION' && content.isFinished && (
                    <span>{validateStudentResponseQuestion}</span>
                  )}
                  {content.referenceType === 'QUIZ' && content.isFinished && (
                    <span>{validateStudentResponseQuiz}</span>
                  )}
                </div>
                {content.isFinished ? (
                  <div className='check_icon_container'>
                    <span className='title_tag'>{I18n.t('routes.panel.knowledgeTrails.update.assignment.statistics.content.view')}</span>
                    <CheckCircleOutlined style={{ color: '#22C55E', fontSize: '24px' }} />
                  </div>
                ) : (
                  <div className='clock_icon_container'>
                    <span className='title_tag'>{I18n.t('routes.panel.knowledgeTrails.update.assignment.statistics.content.notView')}</span>
                    <ClockCircleOutlined style={{ color: '#FBC105', fontSize: '24px' }} />
                  </div>
                )}
              </div>
            )
          })}
        </Col>
      </Content>
    </div>
  );
};

export default StatisticsInfo;
