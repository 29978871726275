import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { TrailsActions } from '../../../../app/redux/actions';
import {
  LoadingSelectors,
  TrailsSelectores,
} from '../../../../app/redux/reducers';
import StatisticsInfo from './StatisticsInfo/StatisticsInfo';
import { Empty, Layout } from 'antd';
import { I18n } from 'react-redux-i18n';

export const StudentsStatisticsComponent = ({ id, location, getTrailStatisticsByStudent, getTrailStatistics }) => {
  // eslint-disable-next-line prefer-destructuring
  const trailId = location.pathname.split('/')[4];
  const [info, setInfo] = useState();
  const loading = useSelector(LoadingSelectors.getLoading);
  const { Content } = Layout;

  useEffect(() => {
    async function getTrailStatistics () {
      if (id && trailId) {
        const data = await getTrailStatisticsByStudent(trailId, id);
        setInfo(data)
      }
    }

    getTrailStatistics()
  }, [ id, trailId, getTrailStatisticsByStudent ]);

  if (!info && !loading) {
    return (
      <Content className='panel__layout__content panel__layout__content--breadcrumb'>
        <Empty
          image={Empty.PRESENTED_IMAGE_DEFAULT}
          description={I18n.t('routes.panel.knowledgeTrails.update.assignment.statistics.notFound')}
        />
      </Content>
    )
  }

  return (
    <StatisticsInfo
      info={info}
      trailId={trailId}
      loading={loading}
    />
  );
};

const mapStateToProps = (state) => ({
  getTrailStatistics: TrailsSelectores.getTrailStatistics(state),
});

const mapDispatchToProps = (dispatch) => ({
  getTrailStatisticsByStudent: (trailId, userId) => dispatch(TrailsActions.getTrailStatisticsByStudent(trailId, userId)),
});

export const StudentsStatistics = connect(
  mapStateToProps,
  mapDispatchToProps,
)(StudentsStatisticsComponent);
